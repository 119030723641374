import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
} from "@material-tailwind/react";
import { BsMic } from "react-icons/bs";
import { Suspense, useEffect, useRef, useState, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import {  mmmhData, okayData, yeahSureData } from "./Blends";
// audio
// import okay from "./BlendAudio/okay.mp3";

// audio
import {
  useGLTF,
  useTexture,
  Loader,
  Environment,
  useFBX,
  useAnimations,
  OrthographicCamera,
} from "@react-three/drei";
import PropagateLoader from "react-spinners/PropagateLoader";
import { MeshStandardMaterial } from "three/src/materials/MeshStandardMaterial";
import { LinearEncoding, sRGBEncoding } from "three/src/constants";
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2 } from "three";
import ReactAudioPlayer from "react-audio-player";

import createAnimation from "./converter";
import blinkData from "./blendDataBlink.json";
import * as THREE from "three";
import axios from "axios";
///////////////////////////////////////////

import { getTokenOrRefresh } from "./token_util";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";

//////////////////////////////////////////
const speechsdk = require("microsoft-cognitiveservices-speech-sdk");
const _ = require("lodash");

function Avatar({
  avatar_url,
  speak,
  responses,
  setResponses,
  setSpeak,
  GetResponse,
  conversation,
  blendData,
  text,
  filename,
  setAudioSource,
  playing,
}) {
  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;

  const [
    bodyTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    // teethSpecularTexture,
    hairTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ] = useTexture([
    "/images/body.webp",
    "/images/eyes.webp",
    "/images/teeth_diffuse.webp",
    "/images/body_specular.webp",
    "/images/body_roughness.webp",
    "/images/body_normal.webp",
    "/images/teeth_normal.webp",
    "/images/h_color.webp",
    "/images/tshirt_diffuse.webp",
    "/images/tshirt_normal.webp",
    "/images/tshirt_roughness.webp",
    "/images/h_alpha.webp",
    "/images/h_normal.webp",
    "/images/h_roughness.webp",
  ]);

  _.each(
    [
      bodyTexture,
      eyesTexture,
      teethTexture,
      teethNormalTexture,
      bodySpecularTexture,
      bodyRoughnessTexture,
      bodyNormalTexture,
      tshirtDiffuseTexture,
      tshirtNormalTexture,
      tshirtRoughnessTexture,
      hairAlphaTexture,
      hairNormalTexture,
      hairRoughnessTexture,
    ],
    (t) => {
      t.encoding = sRGBEncoding;
      t.flipY = false;
    }
  );

  bodyNormalTexture.encoding = LinearEncoding;
  tshirtNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding;

  gltf.scene.traverse((node) => {
    if (
      node.type === "Mesh" ||
      node.type === "LineSegments" ||
      node.type === "SkinnedMesh"
    ) {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Body")) {
        node.castShadow = true;
        node.receiveShadow = true;

        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyTexture;
        // node.material.shininess = 60;
        node.material.roughness = 1.7;

        // node.material.specularMap = bodySpecularTexture;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;

        node.material.envMapIntensity = 0.8;
        // node.material.visible = false;
      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {
        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.7;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.3;
      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();

        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);

        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }
  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {
    if (speak === false) return;

    let data = JSON.stringify({
      conversation: [],
      text: responses.text,
    });


    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://samantha.xavierafrica.com/respond",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        let { aiResponse, audioData, visemes } = response.data;
        const newData = {
          prompt: "",
          conversation: [
            ...responses.conversation,
            { id: responses.conversation.length + 1, assistant: aiResponse },
          ],
          text: text,
        };
        setResponses(newData);

        console.log("RESPONSE OBJECT");
        console.log(newData);
        console.log("RESPONSE OBJECT");

        const binaryString = atob(audioData);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([bytes], { type: "audio/mp3" }); // Adjust MIME type if necessary

        // Create a URL for the Blob and trigger a download

        const url = URL.createObjectURL(blob);

        let newClips = [
          createAnimation(visemes, morphTargetDictionaryBody, "HG_Body"),
          createAnimation(
            visemes,
            morphTargetDictionaryLowerTeeth,
            "HG_TeethLower"
          ),
        ];
        setClips(newClips);
        setAudioSource(url);
      })
      .catch((error) => {
        console.log(error);

      });
    // }, 3000);
  }, [speak]);

  let idleFbx = useFBX("/idle.fbx");
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, (track) => {
    return (
      track.name.includes("Head") ||
      track.name.includes("Neck") ||
      track.name.includes("Spine2")
    );
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, (track) => {
    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;
  });

  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(
      blinkData,
      morphTargetDictionaryBody,
      "HG_Body"
    );
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();
  }, []);

  // Play animation clips when available
  useEffect(() => {
    if (playing === false) return;

    _.each(clips, (clip) => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
    });
  }, [playing]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}

function App() {
  const audioPlayer = useRef();
  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState();
  const [visible, setVisibility] = useState();
  let [loading] = useState(true);
  const [blendData, setBlendData] = useState();
  let [filename, setFileName] = useState();
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [visibleBtn, setVisibleBtn] = useState(false);

  const [topic, setTopic] = useState();
  const [active, setActive] = useState(false);
  const [redColor, setRed] = useState(false);

  const [responses, setResponses] = useState({
    prompt: "",
    conversation: [{ user: "" }, { assistant: "" }],
    text: "",
  });

  const GetResponse = (newData) => {
    setSpeak(true);
  };

  useEffect(() => {
    if (active) {
      const newData = {
        prompt: "",
        conversation: [
          ...responses.conversation,
          { id: responses.conversation.length + 1, user: text },
        ],
        text: text,
      };

      setResponses(newData);

      console.log("RESPONSE OBJECT");
      console.log(newData);
      console.log("RESPONSE OBJECT");

      GetResponse(newData);
    }
  }, [active, text]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
    setVisibility(false);
    setVisibleBtn(false);
    {
      if (responses.conversation) {
        const lastConversation =
          responses.conversation[responses?.conversation.length - 1];
        const isLastFromAssistant =
          lastConversation.hasOwnProperty("assistant");
        if (isLastFromAssistant) {
          sttFromMic();
        }
      }
    }
  }

  // Player is read
  async function playerReady(e) {
    try {
      audioPlayer.current.audioEl.current.play();
      setVisibility(false);
      setPlaying(true);
    } catch (error) {
      alert(error);
    }
  }

  ////////////////////////////////nnnnnnnnnnnnnnnnnneeeeeeeeeeeeeeeeeeeeeeeeeeeewwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww
  const [displayText, setDisplayText] = useState(
    "INITIALIZED: ready to test speech..."
  );

  useEffect(() => {
    // handleOpen();
    const checkToken = async () => {
      const tokenRes = await getTokenOrRefresh();
      if (tokenRes.authToken === null) {
      }
    };
    checkToken();
  }, []);

  // Speech to text method
  const sttFromMic = async () => {
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "en-US";

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    console.log("speak into your microphone...");
    let speechDetected = false;
    let silenceTimer = null;
    const silenceThreshold = 10 * 60 * 1000; // 10 minutes in milliseconds

    const stopRecording = () => {
      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync();
        recognizer.close();
        setRed(false);
      }
      clearTimeout(silenceTimer);
      setDisplayText("Recording stopped due to 1 minute of silence.");
    };

    const resetSilenceTimer = () => {
      clearTimeout(silenceTimer);
      silenceTimer = setTimeout(stopRecording, silenceThreshold);
    };

    // recognizer.recognizeOnceAsync((result) => {
    

    //   console.log(result.privText);

    //   if (result.privText) {
    //     console.log("yes");
    //      setText(result.privText);
        
    //     setActive(true);
    //     setVisibility(true);
    //     speechDetected = true;
    //     resetSilenceTimer();
    recognizer.recognizeOnceAsync((result) => {
      console.time("Response time for speech to text");
      setRed(true);
      let displayText;
     
      if (result.reason === ResultReason.RecognizedSpeech) {
        displayText = `RECOGNIZED: Text=${result.text}`;
        console.log(displayText);
        setText(result.text);
        setActive(true)
        setVisibility(true);
        speechDetected = true;
        resetSilenceTimer();
      }
      if (!speechDetected) {
        console.log("something went wrong boss");
        // sttFromMic();
      }
    });
  };

  return (
    <div className=" w-screen">
      <div className="absolute z-0 w-full">
        <div className="absolute z-10 left-40  md:top-0 mb-10 mx-24 md:mt-24 flex flex-col justify-center items-center space-y-8">
          {visible ? (
            <>
              <PropagateLoader
                color="#c4842c"
                loading={loading}
                size={14}
                speedMultiplier
              />
            
            </>
          ) : (
            ""
          )}
        </div>

        <div class="z-10 absolute rounded-xl font-bold w-full  bottom-0">
          <div className="mic-icon dropdown container mx-auto mb-4 w-fit px-2  py-1 rounded-xl md:px-4  md:py-2 md:rounded-2xl ">
            <BsMic
              color={redColor ? "red" : ""}
              className={`mic-icon `}
              onClick={() => {
                sttFromMic();
              }}
            />
          </div>
        </div>
        <ReactAudioPlayer
          src={audioSource}
          ref={audioPlayer}
          onEnded={playerEnded}
          onCanPlayThrough={playerReady}
        />

        <Canvas
          dpr={2}
          onCreated={(ctx) => {
            ctx.gl.physicallyCorrectLights = true;
          }}
        >
          <OrthographicCamera makeDefault zoom={2000} position={[0, 1.65, 1]} />

          <Suspense fallback={null}>
            <Environment
              background={false}
              files="/images/photo_studio_loft_hall_1k.hdr"
            />
          </Suspense>

          <Suspense fallback={null}>
            <Bg />
          </Suspense>

          <Suspense fallback={null}>
            <Avatar
              avatar_url="/model.glb"
              speak={speak}
              setSpeak={setSpeak}
              text={text}
              responses={responses}
              filename={filename}
              setResponses={setResponses}
              blendData={blendData}
              setAudioSource={setAudioSource}
              playing={playing}
            />
          </Suspense>
        </Canvas>

        <Loader dataInterpolation={(p) => `Loading... please wait`} />
      </div>

      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>
          <h className="text-[#a67c00] ">
            How to use our Talking Avatar: A Step-by-Step Guide
          </h>
        </DialogHeader>
        <DialogBody divider className="grid place-items-center gap-4">
          <div className="w-[32rem] relative">
            <Timeline>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-3">
                  <TimelineIcon />
                  <h4 className="leading-none text-black font-bold">
                    Select Category
                  </h4>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <p className="font-normal text-gray-600 text-sm w-2/3 md:w-full">
                    Select the topic you wish to inquire or gain knowledge
                    about.
                  </p>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-3">
                  <TimelineIcon />
                  <Typography className="leading-none text-black font-bold">
                    Ask a question
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <p className="font-normal text-gray-600 text-sm w-2/3 md:w-full">
                    Pose any relevant questions pertaining to the selected
                    topic.
                  </p>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineHeader className="h-3">
                  <TimelineIcon />
                  <Typography className="leading-none text-black font-bold">
                    Await Response
                  </Typography>
                </TimelineHeader>
                <TimelineBody>
                  <p className="font-normal text-gray-600 text-sm">
                    Await a response.
                  </p>
                </TimelineBody>
              </TimelineItem>
            </Timeline>
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button
            className="bg-[#a67c00] border-none outline-none"
            onClick={handleOpen}
          >
            Ok, Got it
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

function Bg() {
  const texture = useTexture("/images/bg.webp");

  return (
    <mesh position={[0, 1.5, -2]} scale={[1, 0.8, 0.8]}>
      <planeBufferGeometry />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
}

export default App;
